<template>
  <div class="power-list">
    <template v-if="tableData.length > 0">
      <li class="door-item" v-for="item in tableData" :key="item.deviceId">
        <p class="p-top">
          <span>{{ item.deviceName }}</span>
          <el-tag
            :type="item.deviceStatus ? 'success' : 'danger'"
            v-if="item.gatewayStatus == 1"
            >{{ deviceStatusObj[item.deviceStatus] }}</el-tag
          >
          <el-tag type="danger" v-else>网关离线</el-tag>
        </p>
        <P class="p-mid">位置：{{ item.deviceAddress }}</P>
        <p class="p-mid">外部地址：{{ item.deviceNumber }}</p>
        <div class="p-foot">
          <template v-if="item.subDeviceVoList.length == 1">
            <el-switch
              v-for="it in item.subDeviceVoList"
              :key="it.deviceId"
              v-model="it.deviceStatus"
              inactive-text="开关状态"
              :active-value="1"
              :inactive-value="0"
              @change="onOpen(it)"
            ></el-switch>
          </template>
          <template v-if="item.subDeviceVoList.length > 1">
            <el-popover
              placement="right"
              title="开关状态："
              width="100"
              trigger="click"
            >
              <el-switch
                class="switch-item"
                v-for="(it, index) in item.subDeviceVoList"
                :key="it.deviceId"
                v-model="it.deviceStatus"
                :inactive-text="'第' + (index + 1) + '路'"
                :active-value="1"
                :inactive-value="0"
                @change="onOpen(it)"
              ></el-switch>
              <el-button plain type="primary" slot="reference"
                >设备操作</el-button
              >
            </el-popover>
          </template>
        </div>
      </li>
    </template>
    <el-empty class="empty" v-else description="暂无设备信息"></el-empty>
  </div>
</template>

<script>
import { getDeviceList, getDeviceOperate } from "@/api/device/device";
import { deviceStatusObj, deviceStatusColor } from "@/db/objs";
export default {
  data() {
    return {
      deviceStatusObj,
      deviceStatusColor,
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 0,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        deviceType: "11", //设备类型
        gatewayId: "", //网关
        deviceStatus: "", //设备状态
        bindStatus: "1", //绑定状态
      },
      value: true,
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    // 【请求】设备列表
    getDeviceList() {
      let data = this.tableParams;
      getDeviceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
        }
      });
    },

    // 【请求】设备控制
    getDeviceOperate(data) {
      getDeviceOperate(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            this.getDeviceList();
          }, 1000);
        }
      });
    },

    // 【请求】打开电源
    onOpen(it) {
      let data = {
        deviceId: it.deviceId,
        command: it.deviceStatus == 1 ? "On" : "Off",
      };
      this.getDeviceOperate(data);
    },
  },
};
</script>


<style lang="scss" scoped>
.power-list {
  display: flex;
  flex-wrap: wrap;
}

.door-item {
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  margin: 0.15rem 0.15rem 0 0;
  border-radius: 0.1rem;
  width: calc((100% - 0.6rem) / 5);
}

.door-item:nth-child(5n) {
  margin-right: 0;
}

p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-top {
  padding: 0.15rem;
  margin-bottom: 0.15rem;
  border-bottom: 1px solid #f0f2f5;
}

.p-mid {
  color: #999;
  padding: 0.05rem 0.15rem;
}

.p-foot {
  padding: 0.15rem;
  margin-top: 0.15rem;
  border-top: 1px solid #f0f2f5;
  display: flex;
  justify-content: flex-end;
}

.empty {
  width: 100%;
}

.switch-item {
  display: flex;
  justify-content: center;
  margin: 0.1rem 0;
}
</style>