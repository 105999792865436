import request from '@/utils/request'
// 开门列表
export function getOpenList(data) {
  return request('post', '/apm/device/log', data)
}

// 设备列表
export function getDeviceList(data) {
  return request('post', '/apm/device/list', data);
}

// 设备控制
export function getDeviceOperate(data) {
  return request('post', '/apm/device/deviceControl', data);
}

// 门禁开关
export function getOpenDoor(data) {
  return request('post', '/apm/device/normally/open', data);
}

// 查询售卖柜设备详情
export function getCabinetItem(data) {
  return request('post', '/apm/goods/shelves/goodsList', data)
}

// 售卖柜打开货柜
export function getCabinetOpen(data) {
  return request('post', '/apm/goods/shelves/open', data)
}

// 处理小程序码并下载
export function goDealQrcode(data) {
  return request('get', '/apm/device/deal', data)
}

// 小程序码下载
export function goDownloadQrcode(data) {
  return request('get', '/apm/device/download', data)
}

// 小程序码进度条
export function getQrcodeProgress(data) {
  return request('get', '/apm/device/progress', data)
}

// 查询公区设备数量
export function getQrcodeCount(data) {
  return request('get', '/apm/device/public/area/count', data)
}